import { HttpHeaders } from '@angular/common/http';
import { environment } from '@environment/environment';

export class RequestService {
  get httpOptionsWithoutCredentials() {
    return {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/x-www-form-urlencoded'
        }),
      withCredentials: true
    };
  }

  get httpOptionsJsonWithoutCredentials() {
    return {
      headers: new HttpHeaders({
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }),
      withCredentials: true
    };
  }

  get httpOptionsFormDataWithoutCredentials() {
    return {
      headers: new HttpHeaders({}),
      withCredentials: false,
    };
  }

  get httpOptionsWithCredentials() {
    return {
      headers: new HttpHeaders({
        Accept: 'application/json',
        'Set-Cookie': 'JSESSIONID=' + localStorage.getItem('JSESSIONID')
      }),
      withCredentials: true
    };
  }

  get httpOptionsWithCredentialsText() {
    return {
      headers: new HttpHeaders({
        Accept: 'text/plain',
        'Set-Cookie': 'JSESSIONID=' + localStorage.getItem('JSESSIONID')
      }),
      'Content-Type': 'text/plain; charset=utf-8',
      withCredentials: true
    };;
  }

  get httpOptionsWithCredentialsBlob() {
    return {
      headers: new HttpHeaders({
        'Set-Cookie': 'JSESSIONID=' + localStorage.getItem('JSESSIONID')
      }),
      responseType: 'blob' as 'json',
      withCredentials: true
    };;
  }

  get httpOptionsWithCredentialsBlobHeaders() {
    return {
      headers: new HttpHeaders({
        'Set-Cookie': 'JSESSIONID=' + localStorage.getItem('JSESSIONID')
      }),

      observe: 'response' as 'body',
      responseType: 'blob' as 'json',
      withCredentials: true
    };;
  }

  get apiUrl() {
    return environment.apiUrl;
  }
}
